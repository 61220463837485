import request from '@/utils/request';

/**
 * 获取用户列表
 * @param data
 * @returns {AxiosPromise}
 */
export function userList(data) {
  return request({
    url: '/user-web-api/admin/user/info/platform/pageList',
    method: 'POST',
    data
  });
}

/**
 * 批量重置密码
 * @param {data:[]} data
 * @returns
 */
export function resetUserPassword(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/resetPwd',
    method: 'POST',
    data
  });
}

/**
 * 批量更新用户 在职，离职状态
 * @param {userIds:[],isResign}} data
 * @returns
 */
export function changeUsersIsResign(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/updateResign',
    method: 'POST',
    data
  });
}

/**
 *  启用停用
 * @param {userIds:[],isResign}} data
 * @returns
 */
export function changeUsersIsDisable(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/updateDisable',
    method: 'POST',
    data
  });
}

// 开通 启用停用坐席
export function changeSeateStatus(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/updateOpenSeats',
    method: 'POST',
    data
  });
}

/** 查询ERP员工 */
export function searchemp(data) {
  return request({
    url: 'product-web-api/api/erp/searchemp',
    method: 'POST',
    data
  });
}

export default {};
